<template>
  <div class="main jcc ais">
    <Loading v-if="isLoading"></Loading>
    <div class="main__block">
      <div class="title" style="margin-bottom: 30px">選擇預約日期</div>
      <div
        class="flex f-col jcs ais w-100"
        v-if="Object.keys(resvList).length > 0"
      >
        <div
          class="part__cate"
          v-for="(resv, key, index) in resvList"
          :key="key"
        >
          <div
            class="part__cate__title"
            @click="isCollapse[index] = !isCollapse[index]"
          >
            {{ key }}
            <div class="arrow" :class="isCollapse[index] ? 'down' : 'up'"></div>
          </div>
          <transition name="slide-fade">
            <div v-if="isCollapse[index]">
              <div class="part__body">
                <div
                  class=""
                  v-for="(date, key) in resv"
                  :key="key">
                  <div
                    v-if="checkDate(date)"
                    class="choose__item"
                  >
                    <input v-model="resvDate" :value="key" type="radio" />
                    <div class="checkTag">
                      <span>{{ key }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="resvDate" class="title" style="text-align: center;margin-bottom: 30px">選擇預約時間</div>
              <div v-for="(date, key) in resv" :key="key">
                <div class="part__time" v-if="resvDate == key">
                  <div
                    @click="sfname = t.staff.name"
                    :class="{'choose__item': true, 'hide': room.name == '除毛' && parts.indexOf('比基尼') > -1 && t.staff.sex == 1}"
                    v-for="(t) in times"
                    :key="`${key}_${t.time[0]}`"
                  >
                    <div v-if="showTime(t.time[0])" style="width: 100%;height: 100%;">
                      <input
                        v-model="resvTime"
                        :value="t.time[0] + '_' + t.staff.id"
                        type="radio"
                      />
                      <div class="checkTag">
                        <span
                          >{{ calCheckinTime(key, t.time[0], time.checkin) }}<br />{{ t.staff.name }}({{
                            t.staff.sex | gender
                          }})</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <span v-else class="">尚無可預約日期。</span>
      <!-- <div class="title">備註說明事項</div>
      <div class="flex f-col jcs aic w-100">
        <textarea
          v-model="cusNote"
          type="text"
          class="input__input"
          placeholder="請輸入您的問題與特別備註事項"
          maxlength="100"
        ></textarea>
      </div> -->
      <div
        class="flex f-row jce w-100"
        v-if="Object.keys(resv_list).length > 0"
      >
        <div @click="sendResv" class="btn confirm">確認預約</div>
      </div>
      <div
        class="flex f-row jce w-100"
        v-else
        style="margin-top: 20px"
      >
        <div @click="back" class="btn next">上一步</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../components/Loading.vue';
export default {
  name: 'ChooseTime',
  components: {
    Loading,
  },
  data() {
    return {
      isCollapse: {
        0: true,
        1: true,
        2: true,
      },
      resvDate: '',
      resvTime: '',
      times: [],
      sch_note: '',
      sfname: '',
      medTime: {
        schlen: 0,
        checkin: 0
      },
      cusNote: ''
    };
  },
  filters: {
    gender(num = 0) {
      if (num === 0) {
        return '女';
      } else if (num === 1) {
        return '男';
      } else {
        return '';
      }
    },
  },
  watch: {
    resvDate() {
      const vm = this;
      vm.resvTime = '';
      let times = vm.resv_list[vm.month][vm.resvDate];
      if (this.med !== undefined) {
        times = times.filter(item => {
          return this.showTime(item.time[0]);
        });
      }
      vm.times = times;
    },
    async resvTime() {
      if (this.med !== undefined && this.room.name.indexOf('雷美') > -1) {
        // 取雷美預約區間設定之checkin和schlen
        const time = this.calMedTimes(this.resvTime);
        this.medTime = {
          ...time
        };
      } else {
        // 取子項目設定之checkin和schlen
        this.medTime = {
          schlen: this.med.schlen,
          checkin: this.med.checkin
        };
      }
    }
  },
  methods: {
    calMedTimes(resvTime) {
      const time = {
        schlen: 0,
        checkin: 0,
      }
      // let minutesDiff = 0;
      // Split the time string into hours and minutes
      const minutes = resvTime.split('_')[0].split(':')[1];
      const checkin = this.resvHours.find(item => {
        return item.start == minutes;
      });
      // const totalMinutes = hours * 60 + minutes;
      // if (this.med.type == '雷射') {
      //   // Calculate the difference between the total minutes and the next xx:40
      //   minutesDiff = totalMinutes % 60 - 40;
      //   // Adjust the minutes difference if it becomes negative
      //   if (minutesDiff < 0) {
      //     minutesDiff += 60;
      //   }
      // } else if (this.med.type == '微整') {
      //   // Calculate the difference between the total minutes and the next xx:00
      //   minutesDiff = totalMinutes % 60;
      // }
      time.schlen = 10;
      time.checkin = parseInt(checkin.checkin);
      return time;
    },
    isLater(time1, time2) {
      const [hours1, minutes1] = time1.split(':').map(Number);
      const [hours2, minutes2] = time2.split(':').map(Number);

      const date1 = new Date(0, 0, 0, hours1, minutes1);
      const date2 = new Date(0, 0, 0, hours2, minutes2);

      return date1 > date2;
    },
    showTime(time) {
      const t = time.split(':')[1];
      if (this.med !== undefined && this.room.name.indexOf('雷美') > -1) {
        // 膚適美與木木日安的雷射OR微整，才需要時間區塊管理
        // 一小時內，6個區塊能預約什麼
        // const laser = ['00', '10'];
        // const microPlastic = ['30'];
        const resvHours = [];
        this.resvHours.forEach(item => {
          if ((item.booking_id == this.med.booking_id_1) && item.available == '1') {
            // 19:30之後不可約UP雷射
            if(this.med.name == 'UP雷射') {
              if (!this.isLater(time, '19:30')) resvHours.push(item.start);
            } else {
              resvHours.push(item.start);
            }
          }
        });
        // if (this.med.type == '雷射' && laser.indexOf(t) > -1) return true;
        // if (this.med.type == '微整' && microPlastic.indexOf(t) > -1) return true;
        if (resvHours.indexOf(t) > -1) return true;
      } else {
        return true;
      }
      return false;
    },
    checkClinic() {
      const vm = this;
      if (this.$route.query.clinicCode) {
        localStorage.setItem('clinicCode', this.$route.query.clinicCode);
      } else {
        localStorage.removeItem('clinic');
        vm.clinic = {};
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode = this.$route.query.clinicCode;
      this.$router.push(`/login?clinicCode=${clinicCode}`);
    },
    back() {
      this.$router.go(-1);
    },
    checkDate(date) {
      // 確認是否該日無可約診的時間(ex. 除比基尼但當日只有男醫師排班)
      if (date.length == 0) return false;
      if (this.room.name == '除毛' && this.parts.indexOf('比基尼') > -1) {
        const data = date.some(item => { 
          return item.staff.sex == 0;
        });
        return data;
      } else {
        return true;
      }
    },
    async getResvList() {
      const clinicCode = localStorage.getItem('clinicCode');
      try {
        const fd = await this.makeFormData();
        await this.$store.dispatch('resvModule/getResvList', fd);
      } catch (e) {
        console.log(e);
        const tokenError = ['無效的token', 'token錯誤', '該token已失效'];
        if (tokenError.indexOf(e) > -1) {
          alert('請重新登入');
          this.$router.push(`/login?clinicCode=${clinicCode}`);
        } else if (e == 'his api not found') {
          alert('請重新登入');
          this.$router.push(`/login?clinicCode=${clinicCode}`);
        } else {
          alert(e);
        }
      }
    },
    calCheckinTime(date, time, checkin = 0) {
      let dd = date;
      dd = dd.split('-');
      let sch_time = time;

      const y = dd[0];
      const m = dd[1];
      const d = dd[2];

      const tt = sch_time.split(':');
      const h = tt[0];
      const mm = tt[1];
      let dt = new Date(y, m, d, h, mm, 0);
      if (this.med !== undefined && this.room.name.indexOf('雷美') > -1) {
        const t = this.calMedTimes(time);
        checkin = t.checkin;
      }
      dt.setMinutes(dt.getMinutes() - checkin);

      const hour = this.padLeft(dt.getHours(), 2, '0');
      const min = this.padLeft(dt.getMinutes(), 2, '0');
      const checkinTime = `${hour}:${min}`;
      return checkinTime;
    },
    padLeft(str, lenght, padstr = '0') {
      str = '' + str;
      return str.length >= lenght
        ? str
        : new Array(lenght - str.length + 1).join(padstr) + str;
    },
    makeFormData() {
      const fd = new FormData();
      let start,
        end = '';
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      let sy = tomorrow.getFullYear();
      let sm = tomorrow.getMonth() + 1;
      let sd = tomorrow.getDate();
      if (sm < 10) {
        sm = `0${sm}`;
      }
      if (sd < 10) {
        sd = `0${sd}`;
      }
      start = `${sy}-${sm}-${sd}`;

      tomorrow.setMonth(tomorrow.getMonth() + 3);
      let ey = tomorrow.getFullYear();
      let em = tomorrow.getMonth() + 1;
      let ed = tomorrow.getDate();
      if (em < 10) {
        em = `0${em}`;
      }
      if (ed < 10) {
        ed = `0${ed}`;
      }
      end = `${ey}-${em}-${ed}`;
      fd.append('start', start);
      fd.append('end', end);
      fd.append('Authorization', this.token);
      return fd;
    },
    makeSendFormData() {
      const clinicCode = this.$route.query.clinicCode; 
      const online_prefix = JSON.parse(localStorage.getItem(`online_prefix_${clinicCode}`)); 

      const fd = new FormData();
      const sch_time = this.resvTime.split('_')[0];
      const staff_id = this.resvTime.split('_')[1];
      const schlen = this.med !== undefined ? this.medTime.schlen : this.time.schlen;
      const checkin = this.med !== undefined ? this.medTime.checkin : this.time.checkin;
      const room_no = this.room.no;
      const booking_id = this.med !== undefined ? this.med.booking_id_2 : '';
      let sch_note = this.note ? online_prefix + this.note : online_prefix + '無';
      if (this.cusNote) {
        sch_note = `${sch_note},備註:${this.cusNote}`;
      }
      const resv = {
        date: `${this.resvDate} ${sch_time}`,
        item: this.room.name,
        sfname: this.sfname,
        note: this.note,
        time: {
          schlen: schlen,
          checkin: checkin,
        },
      };
      console.log(resv);
      fd.append('date', this.resvDate);
      fd.append('sch_time', sch_time);
      fd.append('schlen', schlen);
      fd.append('checkin', checkin);
      fd.append('staff_id', staff_id);
      fd.append('room_no', room_no);
      if (booking_id !== '') fd.append('booking_id', booking_id);
      fd.append('sch_note', sch_note);
      fd.append('Authorization', this.token);
      fd.append('system_by', 'online');
      if(this.cusType == 'nnp') { // 新患者(未預約過)
        fd.append('mobile', this.nnp.mobile);
        fd.append('name', this.nnp.name);
        fd.append('birthday', this.nnp.birthday);
      } else if (this.cusType == 'np') { // 新患者
        fd.append('mobile', this.newpatient.mobile);
        fd.append('name', this.newpatient.name);
        fd.append('birthday', this.newpatient.birthday);
      }
      return { fd, resv };
    },
    async sendResv() {
      const clinicCode = this.$route.query.clinicCode;
      let fd;
      try {
        if (this.med !== undefined) {
          if (this.med.type == '雷射') {
            let note = `${this.med.name}/${this.medPart}`;
            if (this.medProblem) note += `/${this.medProblem}`;
            await this.$store.dispatch('resvModule/setNote', {
              data: note,
              clinicCode,
            });
            fd = await this.makeSendFormData();
          } else if (this.med.type == '微整') {
            await this.$store.dispatch('resvModule/setNote', {
              data: `${this.med.name}`,
              clinicCode,
            });
            fd = await this.makeSendFormData();
          } else {
            await this.$store.dispatch('resvModule/setNote', {
              data: `${this.med.name}`,
              clinicCode,
            });
            fd = await this.makeSendFormData();
          }
          await this.$store.dispatch('resvModule/sendResv', { data: fd, clinicCode, });
          this.$router.push(`/complete?clinicCode=${clinicCode}`);
        } else {
          fd = await this.makeSendFormData();
          await this.$store.dispatch('resvModule/sendResv', { data: fd, clinicCode, });
          this.$router.push(`/complete?clinicCode=${clinicCode}`);
        }
        
      } catch (e) {
        console.log(e);
        const tokenError = ['無效的token', 'token錯誤', '該token已失效'];
        if (tokenError.indexOf(e) > -1) {
          alert('請重新登入');
          this.$router.push(`/login?clinicCode=${clinicCode}`);
        } else if (e == 'his api not found') {
          alert('請重新登入');
          this.$router.push(`/login?clinicCode=${clinicCode}`);
        } else {
          alert(e);
        }
      }
    },
  },
  computed: {
    ...mapGetters(['tokenByC', 'isLoading']),
    ...mapGetters('authModule', ['customerByC', 'newpatientByC', 'nnpByC', 'resvHoursByC', 'clinicByC']),
    ...mapGetters('resvModule', ['resv_list', 'roomByC', 'noteByC', 'partsByC', 'timeByC', 'medByC', 'medPartByC', 'medProblemByC']),
    clinic() {
      const clinicCode = this.$route.query.clinicCode;
      return this.clinicByC(clinicCode);
    },
    token() {
      const clinicCode = this.$route.query.clinicCode;
      return this.tokenByC(clinicCode);
    },
    parts() {
      const clinicCode = this.$route.query.clinicCode;
      return this.partsByC(clinicCode);
    },
    month() {
      const vm = this;
      let month = vm.resvDate.substring(0, 7);
      return month;
    },
    room() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomByC(clinicCode);
    },
    note() {
      const clinicCode = this.$route.query.clinicCode;
      return this.noteByC(clinicCode);
    },
    time() {
      const clinicCode = this.$route.query.clinicCode;
      return this.timeByC(clinicCode);
    },
    customer() {
      const clinicCode = this.$route.query.clinicCode;
      return this.customerByC(clinicCode);
    },
    newpatient() {
      const clinicCode = this.$route.query.clinicCode;
      return this.newpatientByC(clinicCode);
    },
    nnp() {
      const clinicCode = this.$route.query.clinicCode;
      return this.nnpByC(clinicCode);
    },
    resvHours() {
      const clinicCode = this.$route.query.clinicCode;
      return this.resvHoursByC(clinicCode);
    },
    cusType() {
      let cusType = 'nnp';
      if(Object.keys(this.customer).length > 0) {
        cusType = 'cus';
      }
      if(Object.keys(this.newpatient).length > 0) {
        cusType = 'np';
      }
      return cusType;
    },
    med() {
      const clinicCode = this.$route.query.clinicCode;
      return this.medByC(clinicCode);
    },
    medPart() {
      const clinicCode = this.$route.query.clinicCode;
      return this.medPartByC(clinicCode);
    },
    medProblem() {
      const clinicCode = this.$route.query.clinicCode;
      return this.medProblemByC(clinicCode);
    },
    resvList() {
      let resvList = {};
      resvList = Object.assign({}, this.resv_list);
      // 只限雷美
      if (this.med !== undefined) {
        Object.entries(resvList).forEach(([month, monthData]) => {
          resvList[month] = {};
          Object.entries(monthData).forEach(([date, dateData]) => {
            resvList[month][date] = [];
            dateData.forEach(item => {
              // const time = this.calMedTimes(item.time[0]);
              if (this.showTime(item.time[0])) resvList[month][date].push(item);
            });
          });
        });
      }
      return resvList;
    }
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
    await this.getResvList();
  },
};
</script>
